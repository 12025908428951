<template>
    <div class="market-body">
        <div class="mark-title">站长生日关怀</div>
        <div class="m-t-10">
            {{detailData.stationName}}
        </div>
        <van-cell title="关怀时间" is-link arrow-direction="down"
                  :value="submitPar.activityTime"
                  @click="detailData.taskStatus==='finished'?showActivityTime=false:showActivityTime=true"/>
        <van-calendar v-model="showActivityTime"
                      :min-date="minDate"
                      color="#417C53"
                      @confirm="onConfirm" />


        <van-field v-model="submitPar.participant" :disabled="detailData.taskStatus==='finished'" label="参与人" placeholder="请输入"/>

        <van-cell>
            <div>图片上传</div>
            <van-uploader class="margin-l-20"
                          max-count="3"
                          v-model="fileList"
                          :after-read="afterRead"
                          :before-delete="deletePic"
                          :disabled="detailData.taskStatus==='finished'"

            />
        </van-cell>
        <van-cell style="margin-bottom: 80px;">
            <div>关怀情况</div>
            <van-field
                    v-model="submitPar.activityCondition"
                    rows="2"
                    autosize
                    type="textarea"
                    placeholder="请输入"
                    style="border:1px solid #7E8185"
                    :disabled="detailData.taskStatus==='finished'"

            />
        </van-cell>

        <div class="nextBtn flex" >
            <van-button class="publicBtnActive"
                        style="margin-right: 0.24rem;"
                        v-if="detailData.taskStatus !== 'finished'"
                        @click="submitReport"
            >提交</van-button>
        </div>

    </div>

</template>

<script>
    import {getMyTask,saveFileInfo,saveMasterCareTask}  from '@/utils/api';
    import common from "@/utils/common";
    import { Toast } from 'vant'
    export default {
        name: "masterCare.vue",
        data(){
            return{
                minDate: new Date(2000, 0, 1),
                showActivityTime:false,
                submitPar:{
                    activityTime:'',//活动时间
                    participant:'',//参与人
                    activityCondition:'',//活动情况
                },
                pic:[],//图片
                fileList:[],
                detailData:[],
                newDate:'',
                newTaskNo:'',
            }
        },
        created() {
            //获取当前时间
            let myDate = new Date();
            let startY = myDate.getFullYear();
            let startM = myDate.getMonth() + 1;
            this.newDate = startY +'-'+ (startM < 10 ? '0' + startM :startM);
            this.getTaskDetail();
        },
        methods:{
            formatter(value) {
                // 过滤输入的数字
                return value.replace(/\./, '');
            },
            //获取详情
            getTaskDetail(){
                this.pic = [];
                this.fileList = [];

                getMyTask({taskNo:this.$route.query.taskNo}).then(res=>{
                    console.log('站长关怀详情', res);
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.detailData= res.data.taskInfo;
                        let taskContent =  res.data.taskInfo.taskContent;
                        console.log('taskContent', taskContent);
                        if(taskContent){
                            let data = JSON.parse(taskContent);
                            this.submitPar = {
                                activityTime:data.activityTime,//活动时间
                                participant:data.participant,//参与人
                                activityCondition:data.activityCondition,//活动情况
                            }
                            console.log('pic:'+data.pic);
                            console.log('submitPar:',this.submitPar);
                            this.pic = JSON.parse(data.pic)//图片
                            this.handlePic(this.pic);
                        }
                    }

                })

            },
            //处理回显图片
            handlePic(imgUrlArr){
                let url ='' ;
                for(let i= 0; i< imgUrlArr.length; i++){
                    if(process.env.NODE_ENV === 'production'){
                        url = window.location.origin + '/file/' + imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                    }else{
                        url = 'http://124.70.167.116:20081/file/' +  imgUrlArr[i] + '?Authorization=' + common.getItem('wtToken')
                    }
                    this.fileList.push({url:url})
                }
            },
            //选择时间
            formatDate(date) {
                return `${date.getFullYear()}-${(date.getMonth() + 1)
                < 10 ?'0'+(date.getMonth() + 1):(date.getMonth() + 1)}-${date.getDate() < 10 ? '0'+date.getDate():date.getDate()}`;
            },
            onConfirm(date) {
                this.showActivityTime = false;
                this.submitPar.activityTime = this.formatDate(date);
            },

            afterRead(file, detail){
                // 大于15M
                if(file.file.size > 15 * 1024 * 1024){
                    Toast('图片太大，请上传小于15M的图片')
                    return
                }
                //验证图片格式
                if(!common.imageVerify(file.file.type)){
                    this.fileList.splice(detail.index, 1)
                    Toast('上传图片必须为png或者jpg格式')
                    return
                }
                // 小于100K不进行压缩
                if(file.file.size < 100 * 1024){
                    this.saveFile(file.content)
                    return
                }
                //读取文件内容
                let reader = new FileReader();
                //方法可以将读取到的文件编码成DataURL,可以将资料(例如图片,excel文件)内嵌在网页之中，不用放到外部文件
                reader.readAsDataURL(file.file)
                let _this = this
                reader.onload = function () {
                    let content= file.content //图片的src，base64格式
                    let img = new Image()
                    img.src = content
                    // _this.perviewImage = content;
                    img.onload = function() {
                        common.compress(img, (dataURL)=>{
                            _this.saveFile(dataURL)
                        })
                    }
                }

            },
            deletePic (file, pic) {
                this.fileList.splice(pic.index,1)
                if (file.url){
                    this.pic.splice(pic.index,1)
                }
            },
            saveFile(dataURL) {
                let params = {
                    fileType:'masterCareImg',
                    fileContent: dataURL,
                    stationNo:this.$route.query.stationNo,
                    picOwnNo: common.getItem('uniqueNo'),
                }
                saveFileInfo(params).then(res=>{
                    console.log('res', res);
                    if(res.code === 200 && res.data.businessCode === 200){
                        if(this.pic.length < 4){
                            this.pic.push(res.data.filePath)
                        }
                    }
                })
            },
            submitReport(){
                if(!this.submitPar.activityTime){
                    this.$message.warning('请选择关怀时间!');
                    return;
                }

                if(this.pic.length === 0 ){
                    this.$message.warning('请上传图片!');
                    return;
                }
                if(!this.submitPar.activityCondition){
                    this.$message.warning('请输入关怀情况!');
                    return;
                }
                console.log('this.pic:', this.pic)
                console.log('JSON.stringify(this.pic):', JSON.stringify(this.pic))
                let data = {
                    taskNo:this.$route.query.taskNo,//必须 任务编号
                    stationNo:this.$route.query.stationNo,//站点编号（提交时传）
                    pic: this.pic.length > 0 ? JSON.stringify(this.pic):'',
                    ...this.submitPar,
                }
                console.log('data:', data)
                saveMasterCareTask(data).then(res=>{
                    console.log('提交汇报', res)
                    if(res.code === 200 && res.data.businessCode === 200){
                        this.$message.success('提交成功!')
                        this.getTaskDetail();
                    }
                })

            }
        }
    }
</script>

<style lang="less" scoped>
    .market-body{
        padding: 20px  10px 0  10px;
        .flex {
            display: flex;
        }
        .mark-title{
            font-size:15px;
            font-weight: bold;
        }
        .putInto-box{
            padding: 15px;
            display: flex;
            justify-content: space-between;
            .van-cell{
                padding:0;
            }
        }
        .margin-l-20{
            margin-left: 20px;
        }
        .nextBtn {
            width: 100%;
            padding: 12px;
            background: #f7f8f9;
            position: absolute;
            bottom: 0;
            left: 0;
            .left-btn{
                background: #fff;
                color:#333;
                border: 1px solid #E5E5E5;
                margin-right: 0.24rem;
            }
        }
        .m-t-10{
            margin:10px 5px;
            font-weight: bold;
        }
    }

</style>
