import { render, staticRenderFns } from "./masterCare.vue?vue&type=template&id=a33872ba&scoped=true"
import script from "./masterCare.vue?vue&type=script&lang=js"
export * from "./masterCare.vue?vue&type=script&lang=js"
import style0 from "./masterCare.vue?vue&type=style&index=0&id=a33872ba&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a33872ba",
  null
  
)

export default component.exports